























































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { CreateVaultViewmodel } from '../../viewmodel/create-vault-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: CreateVaultViewmodel
  loading = false

  nextStep() {
    this.loading = true
    setTimeout(() => {
      this.loading = false
      this.vm.nextStep()
    }, 2000)
  }
}
